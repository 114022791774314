import { AppCurrencyService } from '#servicesv2/features/app-currency/app-currency.service';
import { inject } from '@angular/core';
import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { ExampleComponent } from './modules/admin/example/example.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.routes'
                    ),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.routes'
                    ),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.routes'
                    ),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.routes'),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.routes'),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.routes'
                    ),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.routes'),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
            appCurrency: () =>
                inject(AppCurrencyService).loadCurrencyFromCompany(),
        },
        children: [
            {
                path: 'example',
                component: ExampleComponent,
            },
            {
                path: 'dashboard',
                loadChildren: () =>
                    import(
                        '#root/modules/admin/dashboard/dashboard.module'
                    ).then((m) => m.DashboardAIOModule),
            },
            {
                path: 'crm',
                loadChildren: () =>
                    import('#root/modules/admin/crm/crm.module').then(
                        (m) => m.CrmModule
                    ),
            },
            {
                path: 'ecommerce',
                loadChildren: () =>
                    import(
                        '#root/modules/admin/ecommerce/ecommerce.module'
                    ).then((m) => m.EcommerceModule),
            },
            {
                path: 'inventory',
                loadChildren: () =>
                    import(
                        '#root/modules/admin/inventory/inventory.module'
                    ).then((m) => m.InventoryModule),
            },
            {
                path: 'globalConfig',
                loadChildren: () =>
                    import(
                        '#root/modules/admin/global-config/global-config.module'
                    ).then((m) => m.GlobalConfigModule),
            },
            {
                path: 'settings',
                loadChildren: () =>
                    import('#root/modules/admin/settings/settings.module').then(
                        (m) => m.SettingsModule
                    ),
            },
            {
                path: 'error/403',
                loadChildren: () =>
                    import(
                        '#root/modules/miscellaneous/error/error-403/error-403.module'
                    ).then((m) => m.Error403Module),
            },
            {
                path: 'error/404',
                loadChildren: () =>
                    import(
                        '#root/modules/miscellaneous/error/error-404/error-404.module'
                    ).then((m) => m.Error404Module),
            },
            {
                path: 'error/500',
                loadChildren: () =>
                    import(
                        '#root/modules/miscellaneous/error/error-500/error-500.module'
                    ).then((m) => m.Error500Module),
            },
            {
                path: '**',
                loadChildren: () =>
                    import(
                        '#root/modules/miscellaneous/error/error-404/error-404.module'
                    ).then((m) => m.Error404Module),
            },
        ],
    },
];
