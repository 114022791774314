<div class="flex min-w-0 flex-auto flex-col">
    <!-- Main -->
    <div class="flex-auto p-6 sm:p-10">
        <!-- CONTENT GOES HERE -->
        <div
            class="h-400 max-h-400 min-h-400 rounded-2xl border-2 border-dashed border-gray-300"
        >
            <button mat-flat-button color="primary" (click)="print()">
                Imprimir
            </button>
        </div>
    </div>
</div>
