import { Roles } from '#interfacesv2/api/user/enum/roles.enum';
import { RouteEnum, ROUTEROLES } from '#utils/route-roles/route-roles';
import { FuseNavigationItem } from '@fuse/components/navigation';

const hideItemRoles = (roles: Roles[], userRoles: Roles[]) => {
    if (!roles.some((role) => userRoles.includes(role))) {
        // si no hay roles en comun se escondera el item
        return true;
    } else {
        // si se encuentra al menos un match se mostrara
        return false;
    }
};

export default function NavBarByRole(userRoles: Roles[]) {
    const hideItemByRoles = (item: FuseNavigationItem) => {
        return hideItemRoles(getItemRoles(item), userRoles);
    };

    const getItemRoles = (item: FuseNavigationItem) => {
        const hasLink = item.link !== undefined;

        if (hasLink) {
            if (item.link in ROUTEROLES) {
                return ROUTEROLES[item.link].roles;
            }
        }

        if (item.id in ROUTEROLES) {
            return ROUTEROLES[item.id].roles;
        }
        return [];
    };

    const nav: FuseNavigationItem[] = [
        {
            id: RouteEnum.CRM,
            title: 'CRM',
            subtitle: 'Interactua con los clientes',
            type: 'group',
            icon: 'heroicons_outline:home',
            hidden: (item) => hideItemByRoles(item),
            children: [
                {
                    id: 'crm.dashboard.main',
                    title: 'Dashboard',
                    type: 'basic',
                    icon: 'heroicons_outline:chart-pie',
                    link: RouteEnum.DASHBOARD_MAIN,

                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'crm.dashboard.advisor',
                    title: 'Dashboard',
                    type: 'basic',
                    icon: 'heroicons_outline:chart-pie',
                    link: RouteEnum.DASHBOARD_ADVISOR,

                    hidden: (item) => hideItemByRoles(item),
                },

                {
                    id: 'crm.clientes',
                    title: 'Clientes',
                    type: 'basic',
                    icon: 'heroicons_outline:users',
                    link: RouteEnum.CRM_CUSTOMERS,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'crm.opportunity',
                    title: 'Oportunidades',
                    type: 'basic',
                    icon: 'heroicons_outline:shopping-cart',
                    link: RouteEnum.CRM_OPPORTUNITY,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'crm.quotation-requests',
                    title: 'Solicitudes de cotización',
                    type: 'basic',
                    icon: 'heroicons_outline:document',
                    link: RouteEnum.CRM_QUOTATION_REQUESTS,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'crm.negociations',
                    title: 'Negociaciones',
                    type: 'basic',
                    icon: 'heroicons_outline:currency-dollar',
                    link: RouteEnum.CRM_NEGOTIATIONS,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'crm.upcoming-due-opportunities',
                    title: 'Oportunidades por vencer',
                    type: 'basic',
                    icon: 'heroicons_outline:clock',
                    link: RouteEnum.CRM_UPCOMING_DUE_OPPORTUNITIES,
                    hidden: (item) => hideItemByRoles(item),
                },

                {
                    id: 'activities',
                    title: 'Calendario de Actividades',
                    type: 'basic',
                    icon: 'heroicons_outline:calendar',
                    link: RouteEnum.CRM_ACTIVITIES,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'activities-list',
                    title: 'Lista de Actividades pendientes',
                    type: 'basic',
                    icon: 'heroicons_outline:calendar',
                    link: RouteEnum.CRM_ACTIVITIES_LIST,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'customersWallet',
                    title: 'Asesores de venta',
                    type: 'basic',
                    icon: 'heroicons_outline:briefcase',
                    link: RouteEnum.CRM_SALES_ADVISORS,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'crm.invoice',
                    title: 'Facturas',
                    type: 'basic',
                    icon: 'heroicons_outline:document-text',
                    link: RouteEnum.CRM_INVOICE,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'crm.delivery-note',
                    title: 'Entregas',
                    type: 'basic',
                    icon: 'mat_outline:delivery_dining',
                    link: RouteEnum.CRM_DELIVERY_NOTE,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'crm.sale-order',
                    title: 'Ordenes de venta',
                    type: 'basic',
                    icon: 'mat_outline:point_of_sale',
                    link: RouteEnum.CRM_SALE_ORDER,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'crm.business-partners',
                    title: 'Crear socios de negocio',
                    type: 'basic',
                    icon: 'heroicons_outline:user',
                    link: RouteEnum.CRM_BUSINESS_PARTNERS,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: RouteEnum.PENDING,
                    title: 'Pendiente por aprobar',
                    type: 'collapsable',
                    icon: 'heroicons_outline:check',
                    children: [
                        {
                            id: 'pending.discount',
                            title: 'Descuentos',
                            type: 'basic',
                            link: RouteEnum.CRM_PENDING_DISCOUNT,
                            hidden: (item) => hideItemByRoles(item),
                        },
                    ],
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: RouteEnum.PROSPECTS,
                    title: 'Leads',
                    type: 'collapsable',
                    icon: 'heroicons_outline:user-group',
                    children: [
                        {
                            id: `${RouteEnum.PROSPECTS}.usersProspects`,
                            title: 'Leads asignados',
                            type: 'basic',
                            icon: 'heroicons_outline:user-plus',
                            link: RouteEnum.CRM_PROSPECTS,
                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: `${RouteEnum.PROSPECTS}.unassignedProspects`,
                            title: 'Leads sin asignar',
                            type: 'basic',
                            icon: 'heroicons_outline:user-minus',
                            link: RouteEnum.CRM_UNASSIGNED_PROSPECTS,
                            hidden: (item) => hideItemByRoles(item),
                        },
                    ],
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'reports',
                    title: 'Reportes',
                    type: 'collapsable',
                    icon: 'heroicons_outline:folder-open',
                    children: [
                        {
                            id: 'report.purchased-products-by-customer-report',
                            title: 'Unidades vendidas clientes',
                            type: 'basic',
                            link: '/crm/report/purchased-products-by-customer-report',

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'report.customers_advisor',
                            title: 'Clientes por asesores',
                            type: 'basic',
                            link: '/crm/report/customers-advisor',

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'report.quoted_products_customer',
                            title: 'Productos cotizados por clientes',
                            type: 'basic',
                            link: '/crm/report/quoted-products-client',

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'report.advisor_goal',
                            title: 'Metas de Asesores',
                            type: 'basic',
                            link: '/crm/report/advisor-goal',

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'report.top_quote',
                            title: 'Productos mas cotizados',
                            type: 'basic',
                            link: '/crm/report/top-quote',

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'report.top_sold',
                            title: 'Productos más vendidos',
                            type: 'basic',
                            link: '/crm/report/top-sold',

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'report.advisor_activities',
                            title: 'Actividades de los asesores',
                            type: 'basic',
                            link: '/crm/report/advisor-activities',

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'report.opportunity_origins',
                            title: 'Orígenes de oportunidades',
                            type: 'basic',
                            link: '/crm/report/opportunity-origins',

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'report.master',
                            title: 'Reporte de ventas',
                            type: 'basic',
                            link: '/crm/report/master',

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'report.master_quotations',
                            title: 'Maestro Oportunidades',
                            type: 'basic',
                            link: '/crm/report/master-quotations',

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'report.master-leads',
                            title: 'Maestro Leads',
                            type: 'basic',
                            link: '/crm/report/master-leads',

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'report.master-clients',
                            title: 'Maestro Clientes',
                            type: 'basic',
                            link: '/crm/report/master-client',

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'report.master-products',
                            title: 'Maestro Productos',
                            type: 'basic',
                            link: '/crm/report/master-product',

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'report.top-customers',
                            title: 'Top ventas clientes',
                            type: 'basic',
                            link: '/crm/report/top-customers',

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'report.participation-by-subcategory',
                            title: 'Participación por subcategoría',
                            type: 'basic',
                            link: '/crm/report/participation-by-subcategory',

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'report.inventory-rotation',
                            title: 'Rotación de inventario',
                            type: 'basic',
                            link: '/crm/report/inventory-rotation',

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'report.inventory-analysis-by-warehouse',
                            title: 'Análisis de inventario por almacén',
                            type: 'basic',
                            link: '/crm/report/inventory-analysis-by-warehouse',

                            hidden: (item) => hideItemByRoles(item),
                        },
                    ],

                    hidden: (item) => hideItemByRoles(item),
                },
            ],
        },
        {
            id: RouteEnum.INVENTORY,
            title: 'Inventario',
            subtitle: 'Administra los productos',
            type: 'group',
            icon: 'heroicons_outline:home',
            children: [
                {
                    id: 'inventory.products',
                    title: 'Productos',
                    type: 'basic',
                    icon: 'heroicons_outline:shopping-bag',
                    link: RouteEnum.INVENTORY_PRODUCTS,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'inventory.brand',
                    title: 'Marcas',
                    type: 'basic',
                    icon: 'heroicons_outline:hashtag',
                    link: RouteEnum.INVENTORY_BRANDS,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'inventory.categoryProducts.category',
                    title: 'Categorías',
                    type: 'basic',
                    icon: 'heroicons_outline:tag',
                    link: RouteEnum.INVENTORY_CATEGORY_PRODUCTS_CATEGORIES,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'inventory.categoryProducts.subcategory',
                    title: 'Sub categorías',
                    type: 'basic',
                    icon: 'heroicons_outline:tag',
                    link: RouteEnum.INVENTORY_CATEGORY_PRODUCTS_SUBCATEGORIES,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'inventory.characteristic',
                    title: 'Caracteristicas',
                    type: 'basic',
                    icon: 'heroicons_outline:search-circle',
                    link: RouteEnum.INVENTORY_CHARACTERISTICS,
                    hidden: (item) => hideItemByRoles(item),
                },
            ],

            hidden: (item) => hideItemByRoles(item),
        },
        {
            id: RouteEnum.ECOMMERCE,
            title: 'Ecommerce',
            subtitle: 'Gestiona los datos del ecommerce',
            type: 'group',
            icon: 'heroicons_outline:home',
            children: [
                {
                    id: 'ecommerce.businessData',
                    title: 'Datos de la empresa',
                    type: 'basic',
                    icon: 'heroicons_outline:database',
                    link: RouteEnum.ECOMMERCE_BUSINESS_DATA,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'ecommerce.branch',
                    title: 'Sucursales',
                    type: 'basic',
                    icon: 'heroicons_outline:office-building',
                    link: RouteEnum.ECOMMERCE_BRANCH,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'ecommerce.heroSlider',
                    title: 'Hero Slider',
                    type: 'basic',
                    icon: 'heroicons_outline:collection',
                    link: RouteEnum.ECOMMERCE_HERO_SLIDER,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'ecommerce.opinions',
                    title: 'Opiniones',
                    type: 'basic',
                    icon: 'heroicons_outline:pencil',
                    link: RouteEnum.ECOMMERCE_OPINIONS,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'ecommerce.banners',
                    title: 'Banners',
                    type: 'basic',
                    icon: 'heroicons_outline:newspaper',
                    link: RouteEnum.ECOMMERCE_BANNERS,
                    hidden: (item) => hideItemByRoles(item),
                },
            ],

            hidden: (item) => hideItemByRoles(item),
        },
        {
            id: RouteEnum.GLOBAL_CONFIG,
            title: 'Configuraciones Globales',
            subtitle: 'Configura datos, tipos de datos y estados',
            type: 'group',
            icon: 'heroicons_outline:home',
            children: [
                {
                    id: 'globalConfig.businessLine',
                    title: 'Líneas de negocio',
                    type: 'basic',
                    icon: 'mat_solid:business_center',
                    link: RouteEnum.GLOBAL_CONFIG_BUSINESS_LINES,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'globalConfig.businessCostCenter',
                    title: 'Centro de costos',
                    type: 'basic',
                    icon: 'heroicons_outline:cash',
                    link: RouteEnum.GLOBAL_CONFIG_BUSINESS_COST_CENTER,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'globalConfig.businessUnit',
                    title: 'Unidades de negocio',
                    type: 'basic',
                    icon: 'mat_solid:business',
                    link: RouteEnum.GLOBAL_CONFIG_BUSINESS_UNITS,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'globalConfig.leadsSteps',
                    title: 'Etapas de leads',
                    type: 'basic',
                    icon: 'mat_solid:people',
                    link: RouteEnum.GLOBAL_CONFIG_LEADS_STEPS,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'globalConfig.opportunitySteps',
                    title: 'Etapas de oportunidades',
                    type: 'basic',
                    icon: 'mat_solid:equalizer',
                    link: RouteEnum.GLOBAL_CONFIG_OPPORTUNITY_STEPS,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'globalConfig.salesChannel',
                    title: 'Canales de ventas',
                    type: 'basic',
                    icon: 'mat_solid:attach_money',
                    link: RouteEnum.GLOBAL_CONFIG_SALES_CHANNEL,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'globalConfig.pricelists',
                    title: 'Listas de precio',
                    type: 'basic',
                    icon: 'heroicons_outline:currency-dollar',
                    link: RouteEnum.GLOBAL_CONFIG_PRICELISTS,
                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'globalConfig.warehouses',
                    title: 'Almacenes',
                    type: 'basic',
                    icon: 'heroicons_outline:cube',
                    link: RouteEnum.GLOBAL_CONFIG_WAREHOUSES, // Se actualiza aquí

                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'globalConfig.documentType',
                    title: 'Tipos de Documentos',
                    type: 'basic',
                    icon: 'heroicons_outline:document',
                    link: RouteEnum.GLOBAL_CONFIG_DOCUMENT_TYPE, // Se actualiza aquí

                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'globalConfig.typeOrigins',
                    title: 'Tipos de Origen',
                    type: 'basic',
                    icon: 'mat_outline:account_tree',
                    link: RouteEnum.GLOBAL_CONFIG_ORIGIN_TYPE_ORIGINS, // Se actualiza aquí

                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'globalConfig.typeActivity',
                    title: 'Tipos de Actividades',
                    type: 'basic',
                    icon: 'heroicons_outline:calendar',
                    link: RouteEnum.GLOBAL_CONFIG_TYPE_ACTIVITY, // Se actualiza aquí

                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'globalConfig.measureUnit',
                    title: 'Unidades de Medida',
                    type: 'basic',
                    icon: 'heroicons_outline:variable',
                    link: RouteEnum.GLOBAL_CONFIG_MEASURE_UNIT, // Se actualiza aquí

                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'globalConfig.status',
                    title: 'Estados',
                    type: 'collapsable',
                    icon: 'heroicons_outline:check-circle',
                    link: RouteEnum.GLOBAL_CONFIG_STATUS, // Se actualiza aquí
                    children: [
                        {
                            id: 'globalConfig.status.typeStatus',
                            title: 'Estados',
                            type: 'basic',
                            link: RouteEnum.GLOBAL_CONFIG_STATUS_LIST, // Se actualiza aquí

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'globalConfig.status.typeStatus',
                            title: 'Tipos de estados',
                            type: 'basic',
                            link: RouteEnum.GLOBAL_CONFIG_STATUS_TYPE_STATUS, // Se actualiza aquí

                            hidden: (item) => hideItemByRoles(item),
                        },
                    ],

                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'globalConfig.locations',
                    title: 'Locaciones',
                    type: 'collapsable',
                    icon: 'heroicons_outline:globe',
                    link: RouteEnum.GLOBAL_CONFIG_LOCATIONS, // Se actualiza aquí
                    children: [
                        {
                            id: 'globalConfig.locations.regions',
                            title: 'Regiones',
                            type: 'basic',
                            link: RouteEnum.GLOBAL_CONFIG_LOCATIONS_REGIONS, // Se actualiza aquí

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'globalConfig.locations.departments',
                            title: 'Departamentos',
                            type: 'basic',
                            link: RouteEnum.GLOBAL_CONFIG_LOCATIONS_DEPARTMENTS, // Se actualiza aquí

                            hidden: (item) => hideItemByRoles(item),
                        },
                        {
                            id: 'globalConfig.locations.cities',
                            title: 'Municipios',
                            type: 'basic',
                            link: RouteEnum.GLOBAL_CONFIG_LOCATIONS_CITIES, // Se actualiza aquí

                            hidden: (item) => hideItemByRoles(item),
                        },
                    ],

                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'globalConfig.winReasons',
                    title: 'Razones de ganancia',
                    type: 'basic',
                    icon: 'heroicons_outline:trending-up',
                    link: RouteEnum.GLOBAL_CONFIG_WIN_REASONS, // Se actualiza aquí

                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'globalConfig.lossReasons',
                    title: 'Razones de perdida',
                    type: 'basic',
                    icon: 'heroicons_outline:trending-down',
                    link: RouteEnum.GLOBAL_CONFIG_LOSS_REASONS, // Se actualiza aquí

                    hidden: (item) => hideItemByRoles(item),
                },
                {
                    id: 'globalConfig.users',
                    title: 'Usuarios',
                    type: 'basic',
                    icon: 'heroicons_outline:users',
                    link: RouteEnum.GLOBAL_CONFIG_USERS, // Se actualiza aquí

                    hidden: (item) => hideItemByRoles(item),
                },
            ],

            hidden: (item) => hideItemByRoles(item),
        },
    ];

    return nav;
}
