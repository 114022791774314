import { Component, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { PrintProformaInvoiceService } from '../crm/opportunity/dialogs/print-proform/print-proforma-invoice.service';

@Component({
    selector: 'example',
    standalone: true,
    templateUrl: './example.component.html',
    encapsulation: ViewEncapsulation.None,
    imports: [MatButtonModule],
})
export class ExampleComponent {
    /**
     * Constructor
     */
    constructor(
        private printProformaInvoiceServiceService: PrintProformaInvoiceService
    ) {}

    print() {}
}
